import React from "react";
import styled from "@emotion/styled";

import { Wiggle } from "./keyframes";
import BaseTransition, { TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.CSS.Float as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.CSS.Float>
*/

const CSSWiggle: TTransition = ({
    as,
    duration = 5000,
    timing = 'ease-in-out',
    direction = 'alternate',
    iteration = 'infinite',
    delay = 500,
    children,
    ...props
}) => {
    return <Transition
            as={as}
            duration={duration}
            timing={timing}
            direction={direction}
            iteration={iteration}
            delay={delay}
            {...props}>{children}</Transition>;
};

export default CSSWiggle;


const Transition = styled(BaseTransition)`
    will-change: transform;
    animation-name: ${Wiggle};
`;