import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useInView } from "react-intersection-observer";

import { FadeIn } from "./keyframes";
import BaseTransition, { ITransitionProps, TTransition } from "./Transitions.CSS.Base";

import { debounce } from "@utils";

/**
 * Usage:
 * @example
 * <Transitions.JS.FadeIn as={condition ? Link : "div"}>
 *     ...stuff
 * </Transitions.JS.FadeIn>
*/

const JSFadeIn: TTransition = ({
    as,
    duration = 300,
    fillMode = 'forwards',
    active = true,
    children,
    ...props
}) => {
    const [inViewRef, inView] = useInView({ threshold: 0.2, delay: 100, triggerOnce: true });

    useEffect(() => {
        if (props.removeStylesAfter && inView) {
            debounce(() => {
                active = false;
            }, duration + props.delay);
            console.log(duration + props.delay);
        }
    }, [inView]);

    return <Transition
            as={as}
            ref={inViewRef}
            duration={duration}
            fillMode={fillMode}
            fire={inView}
            active={active}
            {...props}>{children}</Transition>;
};

export default JSFadeIn;

const Transition = styled(BaseTransition)<ITransitionProps & { fire?: boolean }>`
    opacity: 0;
    will-change: opacity;
    ${p => p.active && p.fire && css`animation-name: ${FadeIn};`};
`;
