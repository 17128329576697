import React from "react";
import styled from "@emotion/styled";

import { FadeInUp } from "./keyframes";
import BaseTransition, { TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.CSS.FadeInUp as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.CSS.FadeInUp>
*/

const CSSFadeInUp: TTransition = ({ as, children, ...props }) => {
    return <Transition as={as} {...props}>{children}</Transition>;
};

export default CSSFadeInUp;


const Transition = styled(BaseTransition)`
    opacity: 0;
    will-change: opacity, transform;
    animation-name: ${FadeInUp};
`;
