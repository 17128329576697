import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useInView } from "react-intersection-observer";

import { FadeInUp } from "./keyframes";
import BaseTransitionGroup, { ITransitionGroupProps, TTransitionGroup } from "./Transitions.JS.Group";

/**
 * Usage:
 * @example
 * <Transitions.JS.FadeInUpGroup as={condition ? Link : "div"}>
 *     ...stuff
 * </Transitions.JS.FadeInUpGroup>
*/

const JSFadeInUpGroup: TTransitionGroup = ({ as, active = true, children, ...props }) => {
    const [inViewRef, inView] = useInView({ threshold: 0.2, delay: 100, triggerOnce: true });

    return <TransitionGroup as={as} ref={inViewRef} fire={inView} active={active} {...props}>{children}</TransitionGroup>;
};

export default JSFadeInUpGroup;


const TransitionGroup = styled(BaseTransitionGroup)`
    ${p => (p.active && p.selector) && css`
        .${p.selector} {
            opacity: 0;
            will-change: opacity, transform;
            animation-name: ${p.fire ? FadeInUp : `none`};
        }
    `};
    
    ${p => (p.active && !p.selector) && css`
        > * {
            opacity: 0;
            will-change: opacity, transform;
            animation-name: ${p.fire ? FadeInUp : `none`};
        }
    `};
`;