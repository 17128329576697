import CSSFadeIn from "./Transitions.CSS.FadeIn";
import CSSFadeInUp from "./Transitions.CSS.FadeInUp";
import CSSFadeInZoom from "./Transitions.CSS.FadeInZoom";
import CSSFloat from "./Transitions.CSS.Float";
import CSSPulse from "./Transitions.CSS.Pulse";
import CSSWiggle from "./Transitions.CSS.Wiggle";
import JSFadeIn from "./Transitions.JS.FadeIn";
import JSFadeInUp from "./Transitions.JS.FadeInUp";
import JSFadeInZoom from "./Transitions.JS.FadeInZoom";
import JSFadeInGroup from "./Transitions.JS.FadeInGroup";
import JSFadeInUpGroup from "./Transitions.JS.FadeInUpGroup";
import JSFadeInZoomGroup from "./Transitions.JS.FadeInZoomGroup";

export default {
  CSS: {
    FadeIn: CSSFadeIn,
    FadeInUp: CSSFadeInUp,
    FadeInZoom: CSSFadeInZoom,
    Float: CSSFloat,
    Wiggle: CSSWiggle,
    Pulse: CSSPulse
  },
  JS: {
    FadeIn: JSFadeIn,
    FadeInUp: JSFadeInUp,
    FadeInZoom: JSFadeInZoom,
    FadeInGroup: JSFadeInGroup,
    FadeInUpGroup: JSFadeInUpGroup,
    FadeInZoomGroup: JSFadeInZoomGroup,
  }
};
