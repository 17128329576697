import React from "react";
import styled from "@emotion/styled";

import { DropPulse } from "./keyframes";
import BaseTransition, { ITransitionProps } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.CSS.Float as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.CSS.Float>
*/

const CSSPulse: React.FC<ITransitionProps & { color: string; fadedColor: string }> = ({
    as,
    timing = 'ease-in-out',
    iteration = 'infinite',
    delay = 500, 
    color = 'rgba(0, 0, 0, 0.2)',
    fadedColor = 'rgba(0, 0, 0, 0)',
    children,
    ...props
}) => {
    return <Transition
            as={as}
            timing={timing}
            iteration={iteration}
            delay={delay}
            color={color}
            fadedColor={fadedColor}
            {...props}>{children}</Transition>;
};

export default CSSPulse;


const Transition = styled(BaseTransition)<{ color: string; fadedColor: string }>`
    will-change: filter;
    animation-name: ${p => DropPulse(p.color, p.fadedColor)};
`;