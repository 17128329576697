import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useInView } from "react-intersection-observer";

import { FadeInZoom } from "./keyframes";
import BaseTransitionGroup, { ITransitionGroupProps, TTransitionGroup } from "./Transitions.JS.Group";

/**
 * Usage:
 * @example
 * <Transitions.JS.FadeInZoomGroup as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.JS.FadeInZoomGroup>
*/

const JSFadeInZoomGroup: TTransitionGroup = ({
    as,
    duration = 1000,
    delay = 500,
    active = true,
    children,
    ...props
}) => {
    const [inViewRef, inView] = useInView({ threshold: 0.2, delay: 100, triggerOnce: true });
    
    return <TransitionGroup
            as={as}
            ref={inViewRef}
            duration={duration}
            delay={delay}
            fire={inView}
            active={active}
            {...props}>{children}</TransitionGroup>;
};

export default JSFadeInZoomGroup;


const TransitionGroup = styled(BaseTransitionGroup)`
   ${p => (p.active && p.selector) && css`
        .${p.selector} {
            opacity: 0;
            will-change: opacity, transform;
            animation-name: ${p.fire ? FadeInZoom : `none`};
        }
    `};
    
    ${p => (p.active && !p.selector) && css`
        > * {
            opacity: 0;
            will-change: opacity, transform;
            animation-name: ${p.fire ? FadeInZoom : `none`};
        }
    `};
`;