import React from "react";
import styled from "@emotion/styled";

import { FadeIn } from "./keyframes";
import BaseTransition, { TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.CSS.FadeIn as={condition ? Link : "div"}>
 *     ...stuff
 * </Transitions.CSS.FadeIn>
*/

const CSSFadeIn: TTransition = ({ as, duration = 300, children, ...props }) => {
    return <Transition as={as} fillMode={`forwards`} duration={duration} {...props}>{children}</Transition>;
};

export default CSSFadeIn;


const Transition = styled(BaseTransition)`
    opacity: 0;
    will-change: opacity;
    animation-name: ${FadeIn};
`;
