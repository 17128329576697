import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { ITransitionProps, TransitionStyles } from "./Transitions.CSS.Base";

export interface ITransitionGroupProps extends ITransitionProps {
    interval: number;
    selector: string;
    count?: number;
    fire?: boolean;

    contents?: boolean;
};

export type TTransitionGroup = React.FC<ITransitionGroupProps>

/**
 * The is the base component of CSS and JS Group Transitions
*/

const JSGroup: TTransitionGroup = forwardRef(({
    as,
    duration = 2000,
    fillMode = 'both',
    timing = 'cubic-bezier(0.2, 0.8, 0.2, 1)',
    direction = 'initial',
    iteration = 'initial',
    delay = 0,
    stop = false,
    interval = 100, 
    selector = "",
    contents = false,
    active = true,
    children,
    ...props
}, ref) => {
    const classNameFilter = c => c.props.hasOwnProperty('className') && c.props.className.includes(selector);
    const ReactArray = c => React.Children.toArray(c);
    const count = selector ? ReactArray(children).filter(classNameFilter).length : ReactArray(children).length;
    
    return <TransitionGroup
            as={as}
            duration={duration}
            fillMode={fillMode}
            timing={timing}
            direction={direction}
            iteration={iteration}
            delay={delay}
            stop={stop}
            active={active}
            count={count}
            selector={selector}
            interval={interval}
            contents={contents}
            ref={ref}
            {...props}>{children}</TransitionGroup>;
});

export default JSGroup;


const animationDelay = (count: number, interval: number, delay: number) => {
    let styles = {};
    
    for (let i = 0; i < count; i++) {
        styles["&:nth-child(" + (i + 1) + ")"] = {
            animationDelay: (i * interval + delay) + "ms",
        };
    };

    return styles;
};

const TransitionGroup = styled.div<ITransitionGroupProps>`
    ${p => p.fire && p.contents && `display: contents;`};

    ${p => (p.active && p.selector) && css`
        .${p.selector} {
            ${TransitionStyles(p)};
            ${animationDelay(p.count, p.interval, p.delay)};
        }
    `};
    
    ${p => (p.active && !p.selector) && css`
        > * {
            ${TransitionStyles(p)};
            ${animationDelay(p.count, p.interval, p.delay)};
        }
    `};
`;