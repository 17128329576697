import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

export interface ITransitionProps {
    as?: string | Element | React.ReactNode;
    duration: number;
    fillMode: string;
    timing: string;
    direction: string;
    iteration: number | string;
    delay: number;
    stop: boolean;
    active: boolean;
    removeStylesAfter: boolean;
    children?: React.ReactNode | React.ReactNode[] |  Element[];
};

export type TTransition = React.FC<ITransitionProps>

/**
 * The is the base component of CSS and JS Transitions
*/

const CSSBase: TTransition = forwardRef(({
    as,
    duration = 2000,
    fillMode = 'both',
    timing = 'cubic-bezier(0.2, 0.8, 0.2, 1)',
    direction = 'normal',
    iteration = 'initial',
    delay = 0,
    stop = false,
    active = true,
    children,
    ...props
}, ref) => {
    return <Transition
            as={as}
            duration={duration}
            fillMode={fillMode}
            timing={timing}
            direction={direction}
            iteration={iteration}
            delay={delay}
            stop={stop}
            active={active}
            ref={ref}
            {...props}>{children}</Transition>;
});

export default CSSBase;


export const TransitionStyles = (p: ITransitionProps) => css`
    animation-duration: ${p.duration}ms;
    animation-fill-mode: ${p.fillMode};
    animation-timing-function: ${p.timing};
    animation-direction: ${p.direction};
    animation-iteration-count: ${p.iteration};
    ${p.delay && `animation-delay: ${p.delay}ms;`};
    ${p.stop && `animation: none;`};

    // ==========================================================
    // For Chrome -- to have animation smoother
    // https://stackoverflow.com/questions/37629745/why-isnt-my-css3-animation-smooth-in-google-chrome-but-very-smooth-on-other-br
    // ==========================================================
    transform: translateZ(0);
    // backface-visibility: hidden;
    // perspective: 1000;
`;

const Transition = styled.div<ITransitionProps>`
    ${p => p.active && TransitionStyles(p)};
`;
