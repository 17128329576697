import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useInView } from "react-intersection-observer";

import { FadeInUp } from "./keyframes";
import BaseTransition, { ITransitionProps, TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.JS.FadeInUp as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.JS.FadeInUp>
*/

const JSFadeInUp: TTransition = ({ as, active = true, children, ...props }) => {
    const [inViewRef, inView] = useInView({ threshold: 0.2, delay: 100, triggerOnce: true });

    return <Transition as={as} ref={inViewRef} fire={inView} active={active} {...props}>{children}</Transition>;
};

export default JSFadeInUp;

const Transition = styled(BaseTransition)<ITransitionProps & { fire?: boolean }>`
    opacity: 0;
    will-change: opacity, transform;
    ${p => p.fire && css`animation-name: ${FadeInUp};`};
`;
