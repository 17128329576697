import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useInView } from "react-intersection-observer";

import { FadeInZoom } from "./keyframes";
import BaseTransition, { ITransitionProps, TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.JS.FadeInZoom as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.JS.FadeInZoom>
*/

const JSFadeInZoom: TTransition = ({
    as,
    duration = 1000,
    delay = 500,
    active = true,
    children,
    ...props
}) => {
    const [inViewRef, inView] = useInView({ threshold: 0.2, delay: 100, triggerOnce: true });

    return <Transition
            as={as}
            ref={inViewRef}
            duration={duration}
            delay={delay}
            fire={inView}
            active={active}
            {...props}>{children}</Transition>;
};

export default JSFadeInZoom;


const Transition = styled(BaseTransition)<ITransitionProps & { fire?: boolean }>`
    opacity: 0;
    will-change: opacity, transform;
    ${p => p.fire && css`animation-name: ${FadeInZoom};`};
`;