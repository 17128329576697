import React from "react";
import styled from "@emotion/styled";

import { FadeInZoom } from "./keyframes";
import BaseTransition, { TTransition } from "./Transitions.CSS.Base";

/**
 * Usage:
 * @example
 * <Transitions.CSS.FadeInZoom as={condition ? Link : "div"} delay={200}>
 *     ...stuff
 * </Transitions.CSS.FadeInZoom>
*/

const CSSFadeInZoom: TTransition = ({ as, duration = 1000, delay = 500, children, ...props }) => {
    return <Transition as={as} duration={duration} delay={delay} {...props}>{children}</Transition>;
};

export default CSSFadeInZoom;


const Transition = styled(BaseTransition)`
    opacity: 0;
    will-change: opacity, transform;
    animation-name: ${FadeInZoom};
`;